<div class="card">
  <img class="card-img-top embed-responsive embed-responsive-16by9" [src]="model.background" alt="Card image">

  <h3 class="card-header">{{model.header}}</h3>

  <div class="card-body">
    <h5 class="card-title">{{model.title}}</h5>
    <h6 class="card-subtitle text-muted">{{model.subtitle}}</h6>
  </div>

  <div class="card-body">
    <p class="card-text">{{model.comment}}</p>
  </div>

  <div class="card-body">
    <button type="button" class="btn btn-link" (click)="accessTo.emit(model)">Acceder</button>
    <button type="button" class="btn btn-link" (click)="share.emit(model)">Compartir</button>
  </div>

  <div class="card-footer text-muted">
    {{model.creado}}
  </div>
</div>
