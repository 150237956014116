import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Ficha } from 'projects/sena/src/app/class/ficha';
import { Contratista } from '../../class/contratista';
import { Trabajador } from '../../class/trabajador';

@Component({
  selector: 'app-ficha-editar-trabajador',
  templateUrl: './ficha-editar-trabajador.component.html',
  styleUrls: ['./ficha-editar-trabajador.component.scss']
})
export class FichaEditarTrabajadorComponent implements OnInit {

  @Input() fichaTemp:Ficha;
  @Input() contratistas:Contratista[];
  @Output() cerrar = new EventEmitter();
  @Output() guardar = new EventEmitter();
  ficha:Ficha;
  contratistaNuevo = false;
  trabajador : Trabajador;
  newContratista:Contratista = new Contratista();
  // contratista : Contratista;
  params = {sede:"", curso:""};
  constructor(
    private config: NgSelectConfig
  ) { }

  ngOnInit(): void {
    this.ficha = {...this.fichaTemp};
    this.trabajador = {...this.fichaTemp.trabajador};
    if(this.ficha.params!==null){
      this.params = {...this.ficha.params.data};
    }
    // this.contratista = {...this.ficha.contratista}
  }

  verificar(){
    // if(this.contratistaNuevo && this.newContratista.nombre_corto!== undefined && this.newContratista.nombre_corto.trim() !==""){
    //   this.newContratista.razon_social = this.newContratista.nombre_corto;
    //   this.contratista = this.newContratista;
    // }
    this.guardar.emit({ficha:this.ficha,trabajador:this.trabajador, params:this.params});
  }

}

