<div class="jumbotron">
  <h1 class="display-3">Bienvenido  a su Libreria</h1>
  <p class="lead">Accede a tus aplicaciones de forma simple, solo has clic en el boton de acceder.</p>
   <hr class="my-4">
  <!-- <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  <p class="lead">
      <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  </p> -->
</div>

<button class="btn btn-primary mb-3" (click)="collapse=!collapse">Mostrar</button>

<div class="row mb-3" [(ngbCollapse)]="collapse">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <app-library-view [model]="project"></app-library-view>
      </div>
    </div>
     <!-- <unity-webgl-container></unity-webgl-container> -->
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-6 col-md-4" *ngFor="let model of models">
    <app-library-card-app [model]="model" (accessTo)="onAccessTo($event)" (share)="onShare($event)">
    </app-library-card-app>
  </div>
