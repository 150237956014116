<p>unity-webgl-container works!</p>
progress : {{progress}}
<button (click)="fullScreen()">fullscreen</button>
<div class="embed-responsive embed-responsive-21by9">
  <p>
    <ngb-progressbar type="primary" [value]="75"></ngb-progressbar>
  </p>

  <div id="unityContainer" class="unity-container embed-responsive-item"></div>
  <!-- <iframe class="embed-responsive-item" src="..."></iframe> -->
</div>
