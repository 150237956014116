<div class="modal-container">
  <div class="modal-header">
    <h3 class="modal-title">Editar trabajador</h3>
    <button type="button" class="close" aria-label="Close" (click)="cerrar.emit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #form = "ngForm">
    <div class="modal-body">
      <div class="col-12">
        <small class="text-muted">Campos con <span class="text-danger">*</span> son obligatorios</small>
      </div>
      <fieldset class="mb-3">
        <legend class="w-auto">Información Personal</legend>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="cc">Cédula Ciudadania</label>
          <input placeholder="Cédula de Ciudadanía" class="form-control" id="cc" name="cc" disabled [(ngModel)]="trabajador.rut">
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="nombre">Nombre</label>
          <input placeholder="ingrese nombre..." class="form-control" id="nombre" name="nombre" [(ngModel)]="trabajador.nombre">
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="paterno">Apellido Paterno</label>
          <input placeholder="ingrese apellido paterno..." class="form-control" id="paterno" name="paterno" [(ngModel)]="trabajador.paterno">
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="materno">Apellido Materno</label>
          <input placeholder="ingrese apellido materno..." class="form-control" id="materno" name="materno" [(ngModel)]="trabajador.materno">
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="mail">Correo</label>
          <input placeholder="ingrese correo..." type="mail" class="form-control" id="mail" name="mail" [(ngModel)]="trabajador.mail" #email="ngModel"
                 email pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          <small style="color:red" *ngIf="email.value!=='' && email.touched && email.errors">E-mail no valido</small>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="nacimiento">Fecha de Nacimiento</label>
          <input placeholder="ingrese fecha de nacimiento..." type="date" class="form-control" id="nacimiento" name="nacimiento" [(ngModel)]="trabajador.nacimiento">
        </div>
      </fieldset>
      <fieldset class="mb-3">
        <legend class="w-auto">Información de la Evaluación</legend>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="sedeForm">Sede</label>
          <input class="form-control" type="text" name="sedeForm" id="sedeForm" [(ngModel)]="params.sede">
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="sedeForm">ID Curso</label>
          <input class="form-control" type="text" name="sedeForm" id="sedeForm" [(ngModel)]="params.curso">
        </div>
        <!-- <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
            <label for="contratistaForm">Empresa  <span class="text-danger" *ngIf="contratistaNuevo">*</span></label>
            <ng-select name="contratistaForm" *ngIf="!contratistaNuevo" [(ngModel)]="ficha.con_id">
                <ng-option *ngFor="let contratista of contratistas" [value]="contratista.id">{{contratista.nombre_corto}}</ng-option>
            </ng-select>
            <input placeholder="ingrese nombre de la empresa..." *ngIf="contratistaNuevo" type="text" class="form-control" required  [(ngModel)]="newContratista.nombre_corto"
                name="contratistaForm" id="contratistaForm" #c="ngModel">
            <small style="color:red" *ngIf="contratistaNuevo && newContratista?.nombre_corto?.trim()=='' && form.invalid">Campo obligatorio</small>
            <div class="ml-2 custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="contratistaNuevo" id="newContratista" name="newContratista">
                <label for="newContratista" class="custom-control-label">Agregar nuevo contratista</label>
            </div>
        </div> -->
        <div *ngIf="contratistaNuevo" class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
          <label for="nit">NIT</label>
          <input placeholder="ingrese NIT de la empresa..." type="text" class="form-control" [(ngModel)]="newContratista.rut" name="nit" id="nit">
        </div>
      </fieldset>
    </div>
    <div class="modal-footer">
      <div class="d-flex">
        <button [disabled]="form.invalid" class="btn btn-primary justify-content-end" type="submit" (click)="verificar()">Guardar</button>
      </div>
    </div>
  </form>
</div>
