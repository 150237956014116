<div class="container mt-5">
  <div class="row">
    <div class="col">
      <h1>Hola, </h1>
      <h3>Bienvenido/a al entrenador virtual {{project?.header}}</h3>
      <button class="btn btn-primary" (click)="start()">Comenzar</button>
    </div>
  </div>
  <div class="row mb-3" *ngIf="project">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <app-library-view></app-library-view>
        </div>
      </div>
      <!-- <unity-webgl-container></unity-webgl-container> -->
    </div>
  </div>
</div>
