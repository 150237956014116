import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Project } from '../../_class/project';

@Component({
  selector: 'app-library-card-app',
  templateUrl: './library-card-app.component.html',
  styleUrls: ['./library-card-app.component.scss']
})
export class LibraryCardAppComponent {
  @Input() model: Project
  @Output() accessTo = new EventEmitter<Project>();
  @Output() share = new EventEmitter<Project>();
}
