import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { Project } from '../../_class/project';
import { ProjectService } from '../../_services/project.service';
import { SenatiService } from '../../_services/senati.service';
import { TransversalService } from '../../_services/transversal.service';
import { LibraryViewComponent } from '../library-view/library-view.component';

@Component({
  selector: 'app-library-admin',
  templateUrl: './library-admin.component.html',
  styleUrls: ['./library-admin.component.scss']
})
export class LibraryAdminComponent implements OnInit {

  @Input() project: Project
  collapse = true;

  @ViewChild(LibraryViewComponent)
  private viewComponent: LibraryViewComponent;

  models: Project[] = []

  constructor(
    private transversalService: TransversalService
  ) { }

  ngOnInit(): void {
    this.getFirstData()
  }

  async getFirstData() {
    var { body, headers } = await this.transversalService.GetProject().toPromise()
    this.models = body;
    console.log("data", body)
  }

  onShare(name) {
    console.log("Comparte ", name)
  }

  async onAccessTo(model) {
    this.project = await this.transversalService.GetProjectByPk(model.id, { expand: 'builds' }).toPromise();
    console.log("Acceder a ", this.project)
    this.viewComponent.loadProject(this.project.builds[0]?.content.configFile)
    // this.project = model;
    this.collapse = false
  }


  loadProject(url) {
    console.log("url", url)
    this.viewComponent.loadProject(url)
    // this.project = model;
    this.collapse = false
  }
}
