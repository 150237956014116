import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'unity-webgl-container',
  templateUrl: './unity-webgl-container.component.html',
  styleUrls: ['./unity-webgl-container.component.scss']
})
export class UnityWebglContainerComponent implements OnInit {

  unityInstance: any;
  progress
  @Input() appLocation: String = "https://static.qualitatcorp.cl/vrclas/transversal/altura/prueba/Build/Altura.json";
  @Input() appWidth: String;
  @Input() appHeight: String;

  constructor() { }

  ngOnInit() {
    window['receiveMessageFromUnity'] = this.receiveMessageFromUnity;
    if (this.appLocation) {
      this.loadProject(this.appLocation);
    }
  }

  public loadProject(path) {
    this.unityInstance = window['UnityLoader'].instantiate('unityContainer', path, {
      onProgress: (instance, progress) => {
        this.progress = progress
      }
    });
  }

  public sendMessageToUnity(objectName: string, methodName: string, messageValue: string) {
    console.log('sendMessageToUnity', objectName, methodName, messageValue);
    this.unityInstance.SendMessage(objectName, methodName, messageValue);
  }

  public receiveMessageFromUnity(messageValue: string) {
    console.log('receiveMessageFromUnity', messageValue);
  }

  public onProgress(instance, progress) {
    this.progress = progress
    console.log({ instance })
    console.log({ progress })
  }

  public fullScreen() {
    this.unityInstance.SetFullscreen(1)
  }


}
